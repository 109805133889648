import React from "react";
import { Link } from "react-router-dom";
import { getNivelUsuario } from "../../services/auth";

const handleClick = () => {
  window.open(
    "https://leader.web.headcargo.com.br/",
    "_blank",
    "noopener,noreferrer"
  );
};

const Menu = () => (
  <nav className="App__menu">
    <ul className="App__menu__list">
      {getNivelUsuario() === "0" ? (
        <>
          <li className="App__menu__item">
            <Link className="App__menu__link" to="/home">
              DASHBOARD
            </Link>
          </li>
          <li className="App__menu__item">
            <Link className="App__menu__link" to="/outsourcing">
              OUTSOURCING
            </Link>
          </li>
          {/* <li className="App__menu__item">
                    <Link className="App__menu__link" to="/embarque">
                        EMBARQUE
                    </Link>
                </li> */}
          <li className="App__menu__item">
            <Link className="App__menu__link" to="/importacao">
              IMPORTAÇÃO
            </Link>
          </li>
          <li className="App__menu__item">
            <Link className="App__menu__link" to="/exportacao">
              EXPORTAÇÃO
            </Link>
          </li>
          {/* <li className="App__menu__item">
                    <Link className="App__menu__link" to="/kanban">
                        KANBAN
                    </Link>
                </li> */}
          {/* <li className="App__menu__item">
                    <Link className="App__menu__link" to="/processos">
                        PROCESSOS
                    </Link>
                </li> */}
          <li className="App__menu__item">
            <Link className="App__menu__link" to="/trading">
              TRADING
            </Link>
          </li>
          <li
            className="App__menu__item"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          >
            <Link className="App__menu__link">AGENCIAMENTO</Link>
          </li>
          {/* <li className="App__menu__item">
                    <Link className="App__menu__link" to="/financeiro">
                        FINANCEIRO
                    </Link>
                </li> */}
          {/* <li className="App__menu__item">
                    <Link className="App__menu__link" to="/relatorios">
                        RELATÓRIOS
                    </Link>
                </li> */}
        </>
      ) : (
        ""
      )}
      {getNivelUsuario() === "2" || getNivelUsuario() === "1" ? (
        <>
          {/* <li className="App__menu__item">
                        <Link className="App__menu__link" to="/seguro">
                            SEGURO
                        </Link>
                    </li> */}
          <li className="App__menu__item">
            <Link className="App__menu__link" to="/demonstrativo">
              DEMONSTRATIVO
            </Link>
          </li>
          {/* <li className="App__menu__item">
                        <Link className="App__menu__link" to="/notas">
                            NOTAS
                        </Link>
                    </li>    */}
        </>
      ) : (
        ""
      )}
      {getNivelUsuario() === "3" || getNivelUsuario() === "1" ? (
        <>
          <li className="App__menu__item">
            <Link className="App__menu__link" to="/cpagar">
              CONTAS A PAGAR
            </Link>
          </li>
        </>
      ) : (
        ""
      )}
      {getNivelUsuario() === "4" ? (
        <>
          <li className="App__menu__item">
            <Link className="App__menu__link" to="/demonstrativowinning">
              DEMONSTRATIVO
            </Link>
          </li>
        </>
      ) : (
        ""
      )}
      {/* {(getNivelUsuario() === '6')? 
                    <>
                    <li className="App__menu__item">
                        <Link className="App__menu__link" to="/usuarios">
                            USUARIOS
                        </Link>
                    </li>   
                    </>
                    : ""
                    } */}
      {/* {(getNivelUsuario() === '5')? 
                    <>
                    <li className="App__menu__item">
                        <Link className="App__menu__link" to="/demonstrativowinning">
                            DEMONSTRATIVO
                        </Link>
                    </li>   
                    </>
                    : ""
                    } */}
      {/* {getIdUsuario() === '2' ?
            <li className="App__menu__item">
                <Link className="App__menu__link" to="/cadastros">
                    CADASTROS
                </Link>
            </li> 
            :
            null
            } */}
    </ul>
  </nav>
);

export default Menu;
